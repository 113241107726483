
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseSet extends Vue {
  private activeName = 'type'

  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  handleClick () {
    const path = `/productList/baseSet/${this.activeName}`
    if (path !== this.$route.path) {
      this.$router.replace({ path: path })
    }
  }
}
